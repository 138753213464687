import { HolderOutlined, PlusOutlined } from "@ant-design/icons";
import { DragEndEvent, closestCenter, DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useState } from "react";
import { Table, Card, Button, notification, Modal, Form, Input } from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "styled-components";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  getProductCategories,
  changeCategoriesOrder,
  createProductCategory,
} from "../../../service";
import { useMenuContext } from "../../../contexts/menu-context/useMenuContext";

export const CostumCard = styled(Card)`
  width: 100% !important;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
  && .ant-card-body,
  .ant-card-head {
    padding: 8px !important;
  }
  && .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  && .ant-table-cell {
    padding: 8px 8px 8px 4px !important;
  }
`;

interface DataType {
  key: string;
  name: string;
}

const columns: ColumnsType<DataType> = [
  {
    key: "sort",
    width: "0",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    width: "20px",
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const CategoryList: React.FC = () => {
  const { setProductCategoryId, setTableType } = useMenuContext();
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const [categoryName, setCategoryName] = useState<any>("");

  const [form] = Form.useForm();

  const { isLoading } = useQuery<any, Error>(
    ["/product-categories"],
    () => getProductCategories(),
    {
      onSuccess(data) {
        setDataSource(
          data.map((e: any) => {
            return {
              key: e.id,
              ...e,
            };
          })
        );
      },
    }
  );

  const query = useQueryClient();

  const {
    mutateAsync: createProductCategoryMutateAsync,
    isLoading: createProductCategoryMutateIsLoading,
  } = useMutation(createProductCategory, {
    onSuccess: (data) => {
      query.invalidateQueries([`/product-categories`]);
      notification.success({
        message: "Dodano kategorię",
        style: {
          background: "#52c41a30",
        },
      });
    },
    onError: () => {
      notification.error({
        message: "Błąd",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const { mutateAsync, isLoading: mutateIsLoading } = useMutation(
    changeCategoriesOrder,
    {
      onSuccess: (data) => {
        setCategoryName("");
        setDataSource(
          data.map((e: any) => {
            return {
              key: e.id,
              ...e,
            };
          })
        );
        notification.success({
          message: "Zmieniono kolejność",
          style: {
            background: "#52c41a30",
          },
        });
      },
      onError: () => {
        setCategoryName("");
        query.invalidateQueries([`/product-categories`]);
        notification.error({
          message: "Błąd",
          style: {
            background: "#ff4d4f30",
          },
        });
      },
    }
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous: any) => {
        const activeIndex = previous.findIndex((i: any) => i.key === active.id);
        const overIndex = previous.findIndex((i: any) => i.key === over?.id);
        mutateAsync(
          arrayMove(previous, activeIndex, overIndex).map((e: any) => e.id)
        );
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <>
      <CostumCard
        title="Kategorie produktów"
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => setIsNotifyModalOpen(true)}
          >
            Dodaj
          </Button>
        }
        style={{ width: 300 }}
      >
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
          collisionDetection={closestCenter}
        >
          <SortableContext
            items={dataSource
              .sort((a: any, b: any) => a.menuPosition - b.menuPosition)
              .map((i: any) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              loading={isLoading || mutateIsLoading || createProductCategoryMutateIsLoading}
              showHeader={false}
              components={{
                body: {
                  row: Row,
                },
              }}
              onRow={(record: any, rowIndex) => {
                return {
                  onClick: () => {
                    record?.id && setProductCategoryId(record?.id);
                    setTableType("PRODUCTS");
                  },
                };
              }}
              rowKey="key"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </SortableContext>
        </DndContext>
      </CostumCard>
      <Modal
        title="Dodaj kategorię"
        open={isNotifyModalOpen}
        width={500}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log("values", values);
              createProductCategoryMutateAsync({
                name: values.categoryName,
                menuPosition: dataSource?.length ?? 0,
              });
              setCategoryName("");
              setIsNotifyModalOpen(false);
            })
            .catch((errorInfo) => {
              console.error("Validation Failed:", errorInfo);
              setCategoryName("");
              setIsNotifyModalOpen(false);
            });
        }}
        onCancel={() => {
          setCategoryName("");
          setIsNotifyModalOpen(false);
        }}
        destroyOnClose
        closable={true}
        okText={"OK"}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="categoryName"
            label="Nazwa kategorii"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CategoryList;
