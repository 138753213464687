import Icon, { LogoutOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Flex,
  Modal,
  Space,
  Typography,
  notification,
  Menu,
  MenuProps,
} from "antd";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const CostumMenu = styled(Menu)`
  & .ant-menu-item {
    padding-top: 5px;
    padding-inline: calc(50% - 12px - 4px);
  }

  & .ant-menu {
    border: none;
  }
`;

export const UserInfo: React.FC<{ refetch: any; collapsed: any }> = ({
  refetch,
  collapsed,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modal1Open, setModal1Open] = useState(false);

  const [logoutIsLoading, setLogoutIsLoading] = useState(false);
  const logout = async () => {
    setLogoutIsLoading(true);
    try {
      Cookies.remove("accessToken");
      await refetch();
      navigate({
        pathname: location.pathname,
        search: "",
      });
      notification.success({
        message: "Wylogowano",
        style: {
          background: "#52c41a30",
        },
      });
    } catch (error) {
      notification.error({
        message: "Błąd podczas wylogowania",
        style: {
          background: "#ff4d4f30",
        },
      });
      console.error("Wystąpił błąd podczas wylogowywania", error);
      setLogoutIsLoading(false);
    }
  };

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    path: string,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      onClick: () => {
        path && navigate(path);
      },
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem(
      "Chuj wie co to jest",
      "3",
      "settings",
      <Icon
        component={() => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#000000"
            viewBox="0 0 256 256"
          >
            <path d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Zm88-29.84q.06-2.16,0-4.32l14.92-18.64a8,8,0,0,0,1.48-7.06,107.21,107.21,0,0,0-10.88-26.25,8,8,0,0,0-6-3.93l-23.72-2.64q-1.48-1.56-3-3L186,40.54a8,8,0,0,0-3.94-6,107.71,107.71,0,0,0-26.25-10.87,8,8,0,0,0-7.06,1.49L130.16,40Q128,40,125.84,40L107.2,25.11a8,8,0,0,0-7.06-1.48A107.6,107.6,0,0,0,73.89,34.51a8,8,0,0,0-3.93,6L67.32,64.27q-1.56,1.49-3,3L40.54,70a8,8,0,0,0-6,3.94,107.71,107.71,0,0,0-10.87,26.25,8,8,0,0,0,1.49,7.06L40,125.84Q40,128,40,130.16L25.11,148.8a8,8,0,0,0-1.48,7.06,107.21,107.21,0,0,0,10.88,26.25,8,8,0,0,0,6,3.93l23.72,2.64q1.49,1.56,3,3L70,215.46a8,8,0,0,0,3.94,6,107.71,107.71,0,0,0,26.25,10.87,8,8,0,0,0,7.06-1.49L125.84,216q2.16.06,4.32,0l18.64,14.92a8,8,0,0,0,7.06,1.48,107.21,107.21,0,0,0,26.25-10.88,8,8,0,0,0,3.93-6l2.64-23.72q1.56-1.48,3-3L215.46,186a8,8,0,0,0,6-3.94,107.71,107.71,0,0,0,10.87-26.25,8,8,0,0,0-1.49-7.06Zm-16.1-6.5a73.93,73.93,0,0,1,0,8.68,8,8,0,0,0,1.74,5.48l14.19,17.73a91.57,91.57,0,0,1-6.23,15L187,173.11a8,8,0,0,0-5.1,2.64,74.11,74.11,0,0,1-6.14,6.14,8,8,0,0,0-2.64,5.1l-2.51,22.58a91.32,91.32,0,0,1-15,6.23l-17.74-14.19a8,8,0,0,0-5-1.75h-.48a73.93,73.93,0,0,1-8.68,0,8,8,0,0,0-5.48,1.74L100.45,215.8a91.57,91.57,0,0,1-15-6.23L82.89,187a8,8,0,0,0-2.64-5.1,74.11,74.11,0,0,1-6.14-6.14,8,8,0,0,0-5.1-2.64L46.43,170.6a91.32,91.32,0,0,1-6.23-15l14.19-17.74a8,8,0,0,0,1.74-5.48,73.93,73.93,0,0,1,0-8.68,8,8,0,0,0-1.74-5.48L40.2,100.45a91.57,91.57,0,0,1,6.23-15L69,82.89a8,8,0,0,0,5.1-2.64,74.11,74.11,0,0,1,6.14-6.14A8,8,0,0,0,82.89,69L85.4,46.43a91.32,91.32,0,0,1,15-6.23l17.74,14.19a8,8,0,0,0,5.48,1.74,73.93,73.93,0,0,1,8.68,0,8,8,0,0,0,5.48-1.74L155.55,40.2a91.57,91.57,0,0,1,15,6.23L173.11,69a8,8,0,0,0,2.64,5.1,74.11,74.11,0,0,1,6.14,6.14,8,8,0,0,0,5.1,2.64l22.58,2.51a91.32,91.32,0,0,1,6.23,15l-14.19,17.74A8,8,0,0,0,199.87,123.66Z"></path>
          </svg>
        )}
      />
    ),
  ];

  return (
    <>
      <div>
        <CostumMenu theme="light" mode="inline" items={items} />
        <div
          style={{
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingBottom: "8px",
            paddingTop: "12px",
            width: "100%",
          }}
        >
          <Flex
            gap={8}
            justify="center"
            style={{
              marginBottom: "20px",
            }}
          >
            <Space>
              <Badge
                size="small"
                //count={1}
                style={{
                  cursor: "pointer",
                }}
              >
                <Avatar
                  style={{
                    background: "user.icon_color",
                    cursor: "pointer",
                  }}
                  size="small"
                  // onClick={() => setModal1Open(!modal1Open)}
                >
                  {"user.short_name"}
                </Avatar>
              </Badge>

              {!collapsed && (
                <Typography.Text
                  strong
                  style={{ color: "#ffffff", whiteSpace: "nowrap" }}
                >
                  {"user.name"}
                </Typography.Text>
              )}
            </Space>
          </Flex>
          <Flex gap={8} justify="center">
            <Button
              type="link"
              danger
              loading={logoutIsLoading}
              onClick={() => logout()}
              icon={<LogoutOutlined />}
            />
          </Flex>
        </div>
        <Modal
          maskClosable={false}
          title="Powiadomienia"
          style={{ top: 20 }}
          open={modal1Open}
          okText="Odczytaj wszystkie"
          cancelText="Zamknij"
          onOk={() => setModal1Open(false)}
          onCancel={() => setModal1Open(false)}
        >
          {new Array(10).fill("x").map((e) => {
            return (
              <Card
                key={e}
                title="Pushes open the window"
                size="small"
                style={{
                  marginBottom: "20px",
                }}
                extra={<a href="#">Więcej</a>}
              >
                <Space
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  Text Powiadomienia Text Powiadomienia Text Powiadomienia Text
                  Powiadomienia Text Powiadomienia Text Powiadomienia
                </Space>
                <Flex justify="start">
                  <Button size="small" type="primary">
                    Odczytaj
                  </Button>
                </Flex>
              </Card>
            );
          })}
        </Modal>
      </div>
    </>
  );
};
