import { Flex, Layout, Menu, Space } from "antd";

import React from "react";

import Icon from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { MenuProps } from "antd";
import { UserInfo } from "../../components/molecules";
import styled from "styled-components";

const CostumMenu = styled(Menu)`
  & .ant-menu-item {
    padding-top: 5px;
    padding-inline: calc(50% - 12px - 4px);
    margin-bottom: 20px;
  }
`;

export const SlideMenu: React.FC<{
  refetch: any;
  collapsed: any;
  setCollapsed: any;
}> = ({ refetch, collapsed, setCollapsed }) => {
  const navigate = useNavigate();

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    path: string,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      onClick: () => {
        path && navigate(path);
      },
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem(
      "Zamówienia",
      "1",
      "orders",
      <Icon
        component={() => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#000000"
            viewBox="0 0 256 256"
          >
            <path d="M136,120v56a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0ZM239.86,98.11,226,202.12A16,16,0,0,1,210.13,216H45.87A16,16,0,0,1,30,202.12l-13.87-104A16,16,0,0,1,32,80H68.37L122,18.73a8,8,0,0,1,12,0L187.63,80H224a16,16,0,0,1,15.85,18.11ZM89.63,80h76.74L128,36.15ZM224,96H32L45.87,200H210.13Zm-51.16,23.2-5.6,56A8,8,0,0,0,174.4,184a7.44,7.44,0,0,0,.81,0,8,8,0,0,0,7.95-7.2l5.6-56a8,8,0,0,0-15.92-1.6Zm-89.68,0a8,8,0,0,0-15.92,1.6l5.6,56a8,8,0,0,0,8,7.2,7.44,7.44,0,0,0,.81,0,8,8,0,0,0,7.16-8.76Z"></path>
          </svg>
        )}
      />
    ),
    getItem(
      "Rezerwacje",
      "10",
      "reservations",
      <Icon
        component={() => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#000000"
            viewBox="0 0 256 256"
          >
            <path d="M136,120v56a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0ZM239.86,98.11,226,202.12A16,16,0,0,1,210.13,216H45.87A16,16,0,0,1,30,202.12l-13.87-104A16,16,0,0,1,32,80H68.37L122,18.73a8,8,0,0,1,12,0L187.63,80H224a16,16,0,0,1,15.85,18.11ZM89.63,80h76.74L128,36.15ZM224,96H32L45.87,200H210.13Zm-51.16,23.2-5.6,56A8,8,0,0,0,174.4,184a7.44,7.44,0,0,0,.81,0,8,8,0,0,0,7.95-7.2l5.6-56a8,8,0,0,0-15.92-1.6Zm-89.68,0a8,8,0,0,0-15.92,1.6l5.6,56a8,8,0,0,0,8,7.2,7.44,7.44,0,0,0,.81,0,8,8,0,0,0,7.16-8.76Z"></path>
          </svg>
        )}
      />
    ),
    getItem(
      "Menu",
      "2",
      "menu",
      <Icon
        component={() => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#000000"
            viewBox="0 0 256 256"
          >
            <path d="M224,48H160a40,40,0,0,0-32,16A40,40,0,0,0,96,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H96a24,24,0,0,1,24,24,8,8,0,0,0,16,0,24,24,0,0,1,24-24h64a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM96,192H32V64H96a24,24,0,0,1,24,24V200A39.81,39.81,0,0,0,96,192Zm128,0H160a39.81,39.81,0,0,0-24,8V88a24,24,0,0,1,24-24h64ZM160,88h40a8,8,0,0,1,0,16H160a8,8,0,0,1,0-16Zm48,40a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,128Zm0,32a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,160Z"></path>
          </svg>
        )}
      />
    ),
    // getItem(
    //   "Usługi",
    //   "5",
    //   "services",
    //   <Icon
    //     component={() => (
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="25"
    //         height="25"
    //         fill="#000000"
    //         viewBox="0 0 256 256"
    //       >
    //         <path d="M224,48H160a40,40,0,0,0-32,16A40,40,0,0,0,96,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H96a24,24,0,0,1,24,24,8,8,0,0,0,16,0,24,24,0,0,1,24-24h64a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM96,192H32V64H96a24,24,0,0,1,24,24V200A39.81,39.81,0,0,0,96,192Zm128,0H160a39.81,39.81,0,0,0-24,8V88a24,24,0,0,1,24-24h64ZM160,88h40a8,8,0,0,1,0,16H160a8,8,0,0,1,0-16Zm48,40a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,128Zm0,32a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,160Z"></path>
    //       </svg>
    //     )}
    //   />
    // ),
    // getItem(
    //   "Zasoby",
    //   "6",
    //   "resources",
    //   <Icon
    //     component={() => (
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="25"
    //         height="25"
    //         fill="#000000"
    //         viewBox="0 0 256 256"
    //       >
    //         <path d="M224,48H160a40,40,0,0,0-32,16A40,40,0,0,0,96,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H96a24,24,0,0,1,24,24,8,8,0,0,0,16,0,24,24,0,0,1,24-24h64a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM96,192H32V64H96a24,24,0,0,1,24,24V200A39.81,39.81,0,0,0,96,192Zm128,0H160a39.81,39.81,0,0,0-24,8V88a24,24,0,0,1,24-24h64ZM160,88h40a8,8,0,0,1,0,16H160a8,8,0,0,1,0-16Zm48,40a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,128Zm0,32a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,160Z"></path>
    //       </svg>
    //     )}
    //   />
    // ),
    // getItem(
    //   "Chuj wie co to jest",
    //   "3",
    //   "notifications",
    //   <Icon
    //     component={() => (
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="25"
    //         height="25"
    //         fill="#000000"
    //         viewBox="0 0 256 256"
    //       >
    //         <path d="M240,120a48.05,48.05,0,0,0-48-48H152.2c-2.91-.17-53.62-3.74-101.91-44.24A16,16,0,0,0,24,40V200a16,16,0,0,0,26.29,12.25c37.77-31.68,77-40.76,93.71-43.3v31.72A16,16,0,0,0,151.12,214l11,7.33A16,16,0,0,0,186.5,212l11.77-44.36A48.07,48.07,0,0,0,240,120ZM40,199.93V40h0c42.81,35.91,86.63,45,104,47.24v65.48C126.65,155,82.84,164.07,40,199.93Zm131,8,0,.11-11-7.33V168h21.6ZM192,152H160V88h32a32,32,0,1,1,0,64Z"></path>
    //       </svg>
    //     )}
    //   />
    // ),
    getItem(
      "Strefy dostaw",
      "4",
      "delivery",
      <Icon
        component={() => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="#000000"
            viewBox="0 0 256 256"
          >
            <path d="M228.92,49.69a8,8,0,0,0-6.86-1.45L160.93,63.52,99.58,32.84a8,8,0,0,0-5.52-.6l-64,16A8,8,0,0,0,24,56V200a8,8,0,0,0,9.94,7.76l61.13-15.28,61.35,30.68A8.15,8.15,0,0,0,160,224a8,8,0,0,0,1.94-.24l64-16A8,8,0,0,0,232,200V56A8,8,0,0,0,228.92,49.69ZM104,52.94l48,24V203.06l-48-24ZM40,62.25l48-12v127.5l-48,12Zm176,131.5-48,12V78.25l48-12Z"></path>
          </svg>
        )}
      />
    ),
  ];

  return (
    <>
      <Layout.Sider
        // collapsible
        collapsed={true}
        // onCollapse={(value) => setCollapsed(value)}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          background: "#ffffff",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 1000,
        }}
      >
        <Flex
          justify="space-between"
          vertical
          style={{
            height: "100%",
          }}
        >
          <Space direction="vertical">
            <Flex justify="center">
              <div
                onClick={() => navigate("/")}
                style={{
                  cursor: "pointer",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: "10px solid #1677ff",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              />
            </Flex>
            <CostumMenu theme="light" mode="inline" items={items} />
          </Space>
          <UserInfo refetch={refetch} collapsed={collapsed} />
        </Flex>
      </Layout.Sider>
    </>
  );
};
