import React from "react";
import { Col, Row as AntdRow, Breadcrumb } from "antd";


import ServiceList from "../molecules/service-list/ServiceList";
import ResourcesCategoryList from "../molecules/resources-category-list/ResourcesCategoryList";
import ResourceList from "../molecules/resource-list/ResourceList";

const Resources: React.FC = () => {
  return (
    <div
      style={{
        padding: "16px",
      }}
    >
      <Breadcrumb
        style={{
          marginBottom: "16px",
        }}
      >
        <Breadcrumb.Item>Menu</Breadcrumb.Item>
        <Breadcrumb.Item>Zasoby</Breadcrumb.Item>
      </Breadcrumb>
      <AntdRow gutter={[16, 8]}>
        <Col span={6}>
          <ResourcesCategoryList />
          {/* <ServicesAdditionsList /> */}
        </Col>
        <Col span={18}>
          <ResourceList />
        </Col>
      </AntdRow>
    </div>
  );
};

export default Resources;
