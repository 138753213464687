import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Layout, notification } from "antd";

import React, { useState } from "react";
import { useMutation } from "react-query";
import { login } from "../../../service";
import Cookies from "js-cookie";

export const LoginForm: React.FC<{ refetch: any }> = ({ refetch }) => {
  const { mutateAsync, isLoading } = useMutation(login, {
    onSuccess: async (data) => {
      if (data.token) {
        Cookies.set("accessToken", data.token);
        refetch();
        notification.success({
          message: `Witaj`,
          style: {
            background: "#52c41a30",
          },
        });
      } else {
        notification.error({
          message: "Błąd logowania",
          style: {
            background: "#ff4d4f30",
          },
        });
      }
    },
    onError: () => {
      notification.error({
        message: "Błąd logowania",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const [formData, setFormData] = useState({ username: "", password: "" });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    mutateAsync(formData);
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Form onFinish={handleSubmit} style={{ width: 300 }}>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Proszę wprowadzić nazwę użytkownika!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Nazwa użytkownika"
            name="username"
            onChange={handleInputChange}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Proszę wprowadzić hasło!" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Hasło"
            name="password"
            onChange={handleInputChange}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            loading={isLoading}
            htmlType="submit"
            style={{ width: "100%" }}
          >
            Zaloguj się
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};
