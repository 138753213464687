import { Layout } from "antd";

import React, { useState } from "react";

const { Header: AntHeader } = Layout;

export const Header: React.FC = () => {
  const [labels, setLabels] = useState([]);

  return (
    <AntHeader
      style={{
        height: labels?.length > 0 ? "50px" : "0px",
        paddingLeft: "4px",
        paddingRight: "4px",
        lineHeight: 0,
        background: "#fff",
      }}
    ></AntHeader>
  );
};
