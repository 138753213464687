import React, { useState, useRef } from "react";
import { Button, Typography } from "antd";

const { Text } = Typography;

export const HoldButton: React.FC<{ action: any; disabled?: boolean }> = ({
  action,
  disabled,
}) => {
  const [countdown, setCountdown] = useState<number | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const holdDuration = 5; // czas w sekundach

  const handleMouseDown = () => {
    let remainingTime = holdDuration;
    setCountdown(remainingTime);

    timerRef.current = setInterval(() => {
      remainingTime -= 1;
      setCountdown(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(timerRef.current!);
        timerRef.current = null;
        setCountdown(null);
        triggerAction();
      }
    }, 1000);
  };

  const handleMouseUp = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    setCountdown(null);
  };

  const triggerAction = () => {
    action();
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <Button
        disabled={disabled}
        loading={disabled}
        danger
        type="primary"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        style={{ width: "200px", height: "60px", fontSize: "16px" }}
      >
        {countdown !== null ? `Trzymaj (${countdown}s)` : "Zwróć środki"}
      </Button>
      {countdown !== null && (
        <div style={{ marginTop: "10px" }}>
          <Text type="secondary">Przytrzymaj, aby wywołać akcję.</Text>
        </div>
      )}
    </div>
  );
};

