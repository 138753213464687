import { Content as AntContent } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import locale from "antd/lib/locale/pl_PL";
import styled from "styled-components";
import { useQuery } from "react-query";
import { getResources, getServicesCalendarOrders } from "../../../service";
import {
  Badge,
  Calendar as ANTCalendar,
  CalendarProps,
  ConfigProvider,
  Modal,
  Row,
  Spin,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { ModalTaskRow } from "../modal-task-row/ModalTaskRow";
dayjs.locale("pl");

export const Calendar = styled(ANTCalendar)`
  .ant-picker-calendar-header {
    justify-content: flex-start;
    padding: 5px 5px;
  }
  .ant-picker-calendar-date-content {
    height: 114px !important;
  }
  .ant-picker-calendar-mode-switch {
    display: none;
  }
  .events {
    padding: 0;
    margin: 0;
  }
  && .ant-modal-body {
    margin-top: 40px !important;
  }
`;

export const Content = styled(AntContent)`
  padding: 8px;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  margin: 0;
`;

export const ReservationCalendarNew: React.FC<any> = ({
  branch,
  setOpen,
  setReservationId,
}) => {
  const [groups, setGroups] = useState([]);

  const [visibleTimeStartDelay, setVisibleTimeStartDelay] = useState("");
  const [visibleTimeEndDelay, setVisibleTimeEndDelay] = useState("");

  const [taskList, setTaskList] = useState<any>([]);
  const { isLoading: isLoadingGroups } = useQuery<any, Error>(
    ["/resources-in-category"],
    () => getResources({ id: 1 }),
    {
      onSuccess(data) {
        setGroups(
          data.map((e: any) => {
            return {
              id: e.id,
              title: e.name,
            };
          })
        );
      },
    }
  );

  const { isLoading } = useQuery<any, Error>(
    [
      "/service-orders-calendar",
      visibleTimeStartDelay,
      visibleTimeEndDelay,
      branch,
    ],
    () =>
      getServicesCalendarOrders(
        branch,
        visibleTimeStartDelay,
        visibleTimeEndDelay
      ),
    {
      enabled:
        visibleTimeStartDelay !== null &&
        visibleTimeEndDelay !== null &&
        visibleTimeStartDelay !== "" &&
        visibleTimeEndDelay !== "" &&
        !!branch,
      onSuccess(data) {
        setTaskList(data);
      },
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
    }
  );

  const getListData = (value: Dayjs) => {
    return taskList
      .filter((e: any) => {
        return (
          value.format("YYYY-MM-DD") ===
          dayjs.utc(e.startTime).format("YYYY-MM-DD")
        );
      })
      .sort((a: any, b: any) => {
        return (
          dayjs(a.startTime).valueOf() -
          dayjs(b.startTime).valueOf()
        );
      })
      .map((e: any) => {
        return {
          status: e.status,
          content: `${dayjs.utc(e.startTime).format("HH:mm")} - ${
            e.details?.cart?.[0]?.cart_positoin?.meal_name
          }`,
        };
      });
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item: any) => (
          <li key={item.content}>
            <Badge
              color={
                item.status === "ACCEPTED"
                  ? "green"
                  : item.status === "REJECTED"
                  ? "gray"
                  : "red"
              }
              text={item.content}
            />
          </li>
        ))}
      </ul>
    );
  };

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    return info.originNode;
  };

  const getFirstAndLastDayOfMonth = (monthYear: string) => {
    const [month, year] = monthYear.split("-").map(Number);

    const firstDay = dayjs.utc(`${year}-${month}-01`);

    const lastDay = firstDay.endOf("month");

    const formatDate = (date: any) => date.format("YYYY-MM-DD");

    return {
      first_calendar_day: formatDate(firstDay),
      last_calendar_day: formatDate(lastDay),
    };
  };

  const [dataObject, setDataObject] = useState<any>(null);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [value, setValue] = useState<any>(null);

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  useEffect(() => {
    setValue(dayjs.utc());
  }, []);

  useEffect(() => {
    if (value) {
      const firstDayOfMonth = dayjs
        .utc(value)
        .startOf("month")
        .format("YYYY-MM-DD");
      const lastDayOfMonth = dayjs
        .utc(value)
        .endOf("month")
        .format("YYYY-MM-DD");
      setVisibleTimeStartDelay(firstDayOfMonth);
      setVisibleTimeEndDelay(lastDayOfMonth);
    }
  }, [value]);

  return (
    <>
      <Spin spinning={isLoading || isLoadingGroups} fullscreen />
      <ConfigProvider locale={locale}>
        <Calendar
          onSelect={(e, e2) => {
            const tasks = taskList
              .filter(
                (el: any) =>
                  e?.format("YYYY-MM-DD") ===
                  dayjs.utc(el.startTime).format("YYYY-MM-DD")
              )
              .map((el: any) => {
                return (
                  <ModalTaskRow
                    rowData={el}
                    setOpen={setOpen}
                    setReservationId={setReservationId}
                  />
                );
              });
            if (e2.source === "date" && tasks.length > 0) {
              setIsModalOpen2(true);
              setSelectedDate(e);
            }
          }}
          value={value}
          cellRender={cellRender}
          mode="month"
          onChange={(e) => {
            setDataObject(
              getFirstAndLastDayOfMonth(dayjs.utc(e).format("MM-YYYY"))
            );
            setValue(dayjs.utc(e));
          }}
        />
      </ConfigProvider>
      <Modal
        title={selectedDate?.format("YYYY-MM-DD")}
        open={isModalOpen2}
        closable={false}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        okText="Zamknij"
        onOk={handleOk2}
        onCancel={handleCancel2}
        width={700}
        zIndex={1000}
      >
        <Row
          style={{
            borderBottom: "1px solid #ddd",
          }}
        >
          <>
            {taskList
              .filter(
                (e: any) =>
                  selectedDate?.format("YYYY-MM-DD") ===
                  dayjs.utc(e.startTime).format("YYYY-MM-DD")
              )
              .sort((a: any, b: any) => {
                return (
                  dayjs(a.startTime).valueOf() -
                  dayjs(b.startTime).valueOf()
                );
              })
              .map((e: any) => {
                return (
                  <ModalTaskRow
                    rowData={e}
                    setOpen={setOpen}
                    setReservationId={setReservationId}
                  />
                );
              })}
          </>
        </Row>
      </Modal>
    </>
  );
};
