import React, { useState } from "react";
import { useQuery } from "react-query";
import { getServicesOrders } from "../../../service";
import { BranchSelect } from "../../atoms/BranchSelect";

export const ServicesOrdersList: React.FC<any> = ({
  height,
  setBranch,
  branch,
  setOpen,
  setReservationId
}) => {
  const [orders, setOrders] = useState<any>([]);

  useQuery<any, Error>(
    ["/service-orders", branch],
    () => getServicesOrders(branch),
    {
      onSuccess(data) {
        setOrders(data);
      },
      enabled: !!branch,
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
    }
  );

  return (
    <div
      style={{
        height: `calc(100vh - ${height}px)`,
        minHeight: `calc(100vh - ${height}px)`,
        maxHeight: `calc(100vh - ${height}px)`,
        overflow: "auto",
        background: "white",
      }}
    >
      <BranchSelect setBranch={setBranch} branch={branch} />
      {orders.map((e: any) => {
        return (
          <>
            <div
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontWeight: "600",
                background:'rgba(0,0,0,0.2)'
              }}
            >
              {e.day.name}
            </div>
            {e.orders.map((order: any) => {
              return (
                <div
                  onClick={() => {
                    setReservationId(order.id)
                    setOpen(true);
                  }}
                  style={{
                    width: "100%",
                    background: "white",
                    borderLeft: "1px solid #f0f0f0",
                    borderRight: "1px solid #d9d9d9",
                    borderBottom: "1px solid #f0f0f0",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    cursor: "pointer",
                  }}
                >
                  {order.content.map((row: any) => {
                    return (
                      <div
                        style={{
                          display: row.texts.length > 1 ? "flex" : "block",
                          justifyContent:
                            row.texts.length > 1 ? "space-between" : "start",
                          ...row.style,
                        }}
                      >
                        {row.texts.map((text: any) => {
                          return (
                            <span style={{ ...text.style }}>{text.text}</span>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </>
        );
      })}
    </div>
  );
};
