import { Layout } from "antd";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { Delivery } from "../../components/pages/Delivery";
import Menu from "../../components/pages/Menu";
import Services from "../../components/pages/Services";
import Resources from "../../components/pages/Resources";
import { Orders } from "../../components/pages/Orders";
import { Reservations } from "../../components/pages/Reservations";
import { MenuContextProvider } from "../../contexts/menu-context/MenuContextProvider";

export const MainContent: React.FC = () => {
  return (
    <Layout.Content
      style={{
        minHeight: "100%",
      }}
    >
      <Routes>
        <Route path="/" element={<></>} />
        <Route path="/orders/:id?" element={<Orders />} />
        <Route path="/reservations" element={<Reservations />} />
        <Route path="/delivery/:id?" element={<Delivery />} />
        <Route path="/menu" element={ <MenuContextProvider><Menu /></MenuContextProvider>} />
        <Route path="/services/:categoryID?" element={<Services />} />
        <Route
          path="/resources/:resourcesCategoryID?"
          element={<Resources />}
        />
      </Routes>
    </Layout.Content>
  );
};
