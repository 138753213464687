import React, { useState } from "react";
import { Col, Row } from "antd";
import { DeliveryList } from "../organisms/delivery-list/DeliveryList";
import { DeliveryMap } from "../organisms/delivery-map/DeliveryMap";

export const Delivery: React.FC = () => {
  const [zones, setZones] = useState([]);

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        maxHeight: "100%",
        minHeight: "100%",
      }}
    >
      <div
        style={{
          flex: "0 1 auto",
        }}
      >
        <Row>
          <Col span={6}>
            <DeliveryList height={0} setZones={setZones} zones={zones}/>
          </Col>
          <Col span={18}>
            <DeliveryMap zones={zones}/>
          </Col>
        </Row>
      </div>
    </div>
  );
};
