import { Content as AntContent } from "antd/es/layout/layout";
import React, { useState } from "react";

import styled from "styled-components";
import { OrdersHeader } from "../organisms/orders-header/OrdersHeader";
import { Col, Row } from "antd";
import { ServicesOrdersList } from "../organisms/services-orders-list/ServicesOrdersList";
import { ServiceDetailsModal } from "../molecules/service-details-modal/ServiceDetailsModal";
import { ReservationCalendarNew } from "../molecules/reservation-calenda-new/ReservationCalendarNew";

export const Content = styled(AntContent)`
  padding: 8px;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  margin: 0;
`;

export const Reservations: React.FC = () => {
  const [height, setHeight] = useState(0);
  const [branch, setBranch] = useState();
  const [open, setOpen] = useState<any>(false);
  const [reservationId, setReservationId] = useState<any>(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          height: "100%",
          maxHeight: "100%",
          minHeight: "100%",
        }}
      >
        <OrdersHeader setHeight={setHeight} />
        <div
          style={{
            flex: "0 1 auto",
          }}
        >
          <Row>
            <Col span={6}>
              <ServicesOrdersList
                height={height}
                setBranch={setBranch}
                branch={branch}
                setOpen={setOpen}
                setReservationId={setReservationId}
              />
            </Col>
            <Col span={18}>
              <ReservationCalendarNew
                branch={branch}
                setOpen={setOpen}
                setReservationId={setReservationId}
              />
            </Col>
          </Row>
        </div>
      </div>
      <ServiceDetailsModal
        open={open}
        setOpen={setOpen}
        reservationId={reservationId}
        isOrder={false}
      />
    </>
  );
};
