import { HolderOutlined, PlusOutlined } from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useState } from "react";
import {
  Table,
  Card,
  Button,
  Modal,
  Form,
  Input,
  notification,
  ConfigProvider,
  Empty,
} from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "styled-components";
import { useMutation, useQuery } from "react-query";
import {
  createServicesCategories,
  getServicesCategories,
} from "../../../service";
import { Link } from "react-router-dom";

export const CostumCard = styled(Card)`
  width: 100% !important;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
  && .ant-card-body,
  .ant-card-head {
    padding: 8px !important;
  }
  && .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  && .ant-table-cell {
    padding: 8px 8px 8px 4px !important;
  }
`;

interface DataType {
  key: string;
  name: string;
}

const columns: ColumnsType<DataType> = [
  {
    key: "sort",
    width: "0",
  },
  {
    title: "Name",
    dataIndex: "name",
    render(value, record: any) {
      return (
        <Link
          style={{
            textDecoration: "none",
            color: "black",
          }}
          to={`/services/${record.id}`}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {value}
          </div>
        </Link>
      );
    },
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    width: "20px",
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const ServicesCategoryList: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutateAsync, isLoading: createIsLoading } = useMutation(
    createServicesCategories,
    {
      onSuccess: () => {
        form.resetFields();
        setIsModalOpen(false);
        refetch();
      },
      onError: () => {
        notification.error({
          message: "Błąd logowania",
          style: {
            background: "#ff4d4f30",
          },
        });
      },
    }
  );

  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<any>([]);
  const { refetch, isLoading } = useQuery<any, Error>(
    ["/services-cateogry"],
    () => getServicesCategories(),
    {
      onSuccess(data) {
        const tableData = data.map((e: any) => {
          return {
            ...e,
            key: e.menuPosition,
          };
        });
        setDataSource(tableData);
      },
    }
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous: any) => {
        const activeIndex = previous.findIndex((i: any) => i.key === active.id);
        const overIndex = previous.findIndex((i: any) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <>
      <CostumCard
        title="Kategorie"
        extra={
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => setIsModalOpen(true)}
          >
            Dodaj
          </Button>
        }
        style={{ width: 300 }}
      >
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={dataSource.map((i: any) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <ConfigProvider
              renderEmpty={() => (
                <Empty description="Custom message">
                  <Button type="primary" onClick={() => setIsModalOpen(true)}>
                    Dodaj kategorię
                  </Button>
                </Empty>
              )}
            >
              <Table
                loading={isLoading || createIsLoading}
                showHeader={false}
                components={{
                  body: {
                    row: Row,
                  },
                }}
                rowKey="key"
                columns={columns}
                dataSource={dataSource}
                pagination={false}
              />
            </ConfigProvider>
          </SortableContext>
        </DndContext>
      </CostumCard>
      <Modal
        title="Dodawanie kategorii usługi"
        open={isModalOpen}
        onOk={() => mutateAsync(form.getFieldsValue())}
        onCancel={() => {
          form.resetFields();
          setIsModalOpen(false);
        }}
    
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Field A" name="name" rules={[{ required: true }]}>
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item label="Field A" name="description">
            <Input.TextArea placeholder="input placeholder" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ServicesCategoryList;
