import React from "react";
import { Col, Row as AntdRow, Breadcrumb } from "antd";

import ServicesCategoryList from "../molecules/services-category-list/ServicesCategoryList";
import ServicesAdditionsList from "../molecules/services-additions-list/ServicesAdditionsList";
import ServiceList from "../molecules/service-list/ServiceList";

const Services: React.FC = () => {
  return (
    <div
      style={{
        padding: "16px",
      }}
    >
      <Breadcrumb
        style={{
          marginBottom: "16px",
        }}
      >
        <Breadcrumb.Item>Menu</Breadcrumb.Item>
        <Breadcrumb.Item>Usługi</Breadcrumb.Item>
      </Breadcrumb>
      <AntdRow gutter={[16, 8]}>
        <Col span={6}>
          <ServicesCategoryList />
          {/* <ServicesAdditionsList /> */}
        </Col>
        <Col span={18}>
          <ServiceList />
        </Col>
      </AntdRow>
    </div>
  );
};

export default Services;
