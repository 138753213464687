import { HolderOutlined, PlusOutlined } from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useState } from "react";
import { Table, Card, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "styled-components";

export const CostumCard = styled(Card)`
  width: 100% !important;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
  && .ant-card-body,
  .ant-card-head {
    padding: 8px !important;
  }
  && .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  && .ant-table-cell {
    padding: 8px 8px 8px 4px !important;
  }
`;

interface DataType {
  key: string;
  name: string;
}

const columns: ColumnsType<DataType> = [
  {
    key: "sort",
    width: "0",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    width: "20px",
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const AdditionsList: React.FC = () => {
  const [dataSource, setDataSource] = useState([
    {
      key: "1",
      name: "Dodatki do pizzy",
    },
    {
      key: "2",
      name: "Sosy",
    },
    {
      key: "3",
      name: "Dodatki mięsne",
    },
    {
      key: "3",
      name: "Dodatki warzywne",
    },
  ]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <CostumCard
      title="Kategorie dodatków"
      extra={
        <Button icon={<PlusOutlined />} type="primary">
          Dodaj
        </Button>
      }
      style={{ width: 300 }}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            showHeader={false}
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </CostumCard>
  );
};

export default AdditionsList;
