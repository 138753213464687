import { Button, Flex, Modal, Space, notification, Dropdown, Tag } from "antd";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getServiceOrder, updateServiceOrders } from "../../../service";
import styled from "styled-components";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { makeRefund } from "../../../service";
import { HoldButton } from "../../atoms/HoldButton";
import { MoreOutlined } from "@ant-design/icons";

dayjs.extend(utc);
dayjs.extend(timezone);
const Meal = styled.div``;

export const P = styled.p<{
  $size: string;
  $weight: string;
  $height: string;
  $family?: string;
}>`
  margin: 0;
  font-family: ${({ $family = "Lato" }) => $family}, sans-serif;
  font-size: ${({ $size }) => $size}px;
  font-weight: ${({ $weight }) => $weight};
  line-height: ${({ $height }) => $height}px;
`;

export const ServiceDetailsModal: React.FC<any> = ({
  setOpen,
  open,
  reservationId,
  isOrder,
}) => {
  const [content, setContent] = useState<any>();
  const hideModal = () => {
    setOpen(false);
  };

  useQuery<any, Error>(
    ["/service-order", reservationId],
    () => reservationId && getServiceOrder({ id: reservationId }),
    {
      onSuccess(data) {
        setContent(data);
      },
      enabled: !!open && !!reservationId,
    }
  );

  const query = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(updateServiceOrders, {
    onSuccess: () => {
      query.invalidateQueries([`/service-order`]);
      query.invalidateQueries([`/services`]);
      query.invalidateQueries([`/service-orders-calendar`]);
      notification.success({
        message: "Status został zmieniony",
        style: {
          background: "#52c41a30",
        },
      });
    },
    onError: () => {
      notification.error({
        message: "Błąd podczas zmiany statusu",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  const updateStatus = async (status: string) => {
    await mutateAsync({
      id: content.id,
      body: {
        status: status,
      },
    });
  };

  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);

  const {
    mutateAsync: refundMutateAsync,
    isLoading: refundMutateIsLoading,
  } = useMutation(makeRefund, {
    onSuccess: () => {
      query.invalidateQueries([`/service-order`]);
      query.invalidateQueries([`/services`]);
      query.invalidateQueries([`/service-orders-calendar`]);
      setIsNotifyModalOpen(false);
      notification.success({
        message: "Środki zostały zwrócone",
        style: {
          background: "#52c41a30",
        },
      });
    },
    onError: () => {
      setIsNotifyModalOpen(false);
      notification.error({
        message: "Błąd podczas tworzenia zwrotu",
        style: {
          background: "#ff4d4f30",
        },
      });
    },
  });

  if (!content) return null;
  return (
    <>
      <Modal
        title={
          <>
            Rezerwacja numer: {reservationId} -{" "}
            {content.status === "REJECTED" ? (
              <span style={{ color: "grey" }}>Odrzucona</span>
            ) : content.status === "ACCEPTED" ? (
              <span style={{ color: "green" }}>Zatwierdzona</span>
            ) : (
              <span style={{ color: "red" }}>Oczekuje na zatwierdzenie</span>
            )}
          </>
        }
        open={open}
        onCancel={hideModal}
        footer={(_, { CancelBtn }) => (
          <Flex
            justify={content.status !== "REJECTED" ? "space-between" : "end"}
          >
            {content.status === "IN_QUEUE" && (
              <Flex gap={8}>
                <Button
                  danger
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={() => updateStatus("REJECTED")}
                >
                  Odrzuć
                </Button>
                <Button
                  style={{
                    border: "1px solid green",
                    color: "green",
                  }}
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={() => updateStatus("ACCEPTED")}
                >
                  Akceptuj
                </Button>
              </Flex>
            )}
            {content.status === "ACCEPTED" && (
              <Flex gap={8}>
                <Button danger onClick={() => updateStatus("REJECTED")}>
                  Odwołaj
                </Button>
              </Flex>
            )}
            <CancelBtn />
          </Flex>
        )}
        cancelText="Zamknij"
        zIndex={1100}
      >
        <Meal>
          <Space>
            <P $size="14" $weight="600" $height="20">
              {"Dane rezerwujacego"}
            </P>
          </Space>

          <Flex
            vertical
            style={{
              marginBottom: "10px",
            }}
          >
            <Space
              style={{
                columnGap: "4px",
              }}
            >
              <span>
                <P $size="14" $height="16" $weight="400" $family="Lato">
                  Imie i nazwisko:
                </P>
              </span>
              <span>
                <P $size="14" $height="20" $weight="300" $family="Lato-Light">
                  {content?.clientName}
                </P>
              </span>
            </Space>
            <Space
              style={{
                columnGap: "4px",
              }}
            >
              <span>
                <P $size="14" $height="16" $weight="400" $family="Lato">
                  Telefon:
                </P>
              </span>
              <span>
                <P $size="14" $height="20" $weight="300" $family="Lato-Light">
                  {content?.telNumber}
                </P>
              </span>
            </Space>
            <Space
              style={{
                columnGap: "4px",
              }}
            >
              <span>
                <P $size="14" $height="16" $weight="400" $family="Lato">
                  E-mail:
                </P>
              </span>
              <span>
                <P $size="14" $height="20" $weight="300" $family="Lato-Light">
                  {content?.email}
                </P>
              </span>
            </Space>
          </Flex>
          <Space
            style={{
              marginBottom: "10px",
            }}
          >
            <P $size="14" $weight="600" $height="20">
              {isOrder ? "Zamówienie" : "Rezerwacja"}
            </P>
          </Space>

          <Flex
            vertical
            style={{
              marginBottom: "10px",
            }}
          >
            {content?.details?.cart?.map((control: any) => {
              return (
                <Space
                  style={{
                    columnGap: "4px",
                  }}
                >
                  <span>
                    <P $size="14" $height="16" $weight="400" $family="Lato">
                      {control?.cart_positoin.meal_quantity}
                      {"x "}
                      {control?.cart_positoin.meal_name} -{" "}
                      {(
                        (control?.cart_positoin.meal_quantity *
                          control?.cart_positoin.meal_price) /
                        100
                      ).toFixed(2)}
                      {" zł"}
                    </P>
                  </span>
                  <span>
                    <P
                      $size="14"
                      $height="20"
                      $weight="300"
                      $family="Lato-Light"
                    >
                      {control?.value}
                    </P>
                  </span>
                </Space>
              );
            })}
          </Flex>
          <Space
            style={{
              marginBottom: "10px",
            }}
          >
            <P $size="14" $weight="600" $height="20">
              {"Termin"}
            </P>
          </Space>

          <Flex
            vertical
            style={{
              marginBottom: "10px",
            }}
          >
            <Space
              style={{
                columnGap: "4px",
              }}
            >
              <span>
                <P $size="14" $height="16" $weight="400" $family="Lato">
                  {dayjs.utc(content?.startTime).format("DD-MM-YYYY HH:mm")}
                </P>
              </span>
            </Space>
          </Flex>
          <Space
            style={{
              marginBottom: "10px",
            }}
          >
            <P $size="14" $weight="600" $height="20">
              {"Wykonanie usługi"}
            </P>
          </Space>

          <Flex
            vertical
            style={{
              marginBottom: "10px",
            }}
          >
            {content.resources?.map((resource: any) => {
              return (
                <Space
                  style={{
                    columnGap: "4px",
                  }}
                >
                  {resource.resource.resourceCategory.name +
                    ": " +
                    resource.resource.name}
                </Space>
              );
            })}
          </Flex>
        </Meal>
        {content?.details?.additionalSections.map(
          ({ sectionName, controls }: any) => {
            return (
              <Meal>
                <Space
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <P $size="14" $weight="600" $height="20">
                    {sectionName}
                  </P>
                </Space>

                <Flex
                  vertical
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  {controls.map((control: any) => {
                    return (
                      <Space
                        style={{
                          columnGap: "4px",
                        }}
                      >
                        <span>
                          <P
                            $size="14"
                            $height="16"
                            $weight="400"
                            $family="Lato"
                          >
                            {control.name}:
                          </P>
                        </span>
                        <span>
                          <P
                            $size="14"
                            $height="20"
                            $weight="300"
                            $family="Lato-Light"
                          >
                            {control.value}
                          </P>
                        </span>
                      </Space>
                    );
                  })}
                </Flex>
              </Meal>
            );
          }
        )}
        <Meal>
          <Flex
            justify="space-between"
            style={{
              marginTop: "20px",
              marginBottom: "10px",
            }}
            align="center"
          >
            <Space>
              <P $size="14" $weight="600" $height="20">
                Płatność
              </P>
            </Space>
            {content.details.paymentsType === "PRZELEWY24" && !content.refund && (
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "1",
                      label: (
                        <div onClick={() => setIsNotifyModalOpen(true)}>
                          Zwróć środki
                        </div>
                      ),
                    },
                  ],
                }}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
              >
                <Button icon={<MoreOutlined />} size="small" />
              </Dropdown>
            )}
            {content.refund && <Tag color={"red"}>{`Zwrócono środki`}</Tag>}
          </Flex>

          <Flex
            vertical
            style={{
              marginBottom: "10px",
            }}
          >
            <Space
              style={{
                columnGap: "4px",
              }}
            >
              <span>{content?.details?.payments}</span>
            </Space>
          </Flex>
        </Meal>
      </Modal>
      <Modal
        zIndex={10000}
        title="Zwróć środki. Tej operacji nie mozna cofnąć!"
        open={isNotifyModalOpen}
        width={500}
        onOk={() => {
          setIsNotifyModalOpen(false);
        }}
        onCancel={() => {
          setIsNotifyModalOpen(false);
        }}
        footer={() => (
          <HoldButton
            disabled={refundMutateIsLoading}
            action={() => {
              refundMutateAsync({ serviceOrderId: content.id });
            }}
          />
        )}
        destroyOnClose
        closable={true}
        okText={"OK"}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        Przytrzymaj przycisk "Zwróć środki" przez 5 sekund dla potwierdzenia
        operacji.
      </Modal>
    </>
  );
};
