import { Select } from "antd";
import { Content as AntContent } from "antd/es/layout/layout";
//AIzaSyA3lh6-T9qUYkdo46jFI1jaNQ_z2JNdCEs
import React, { useState } from "react";
import { useQuery } from "react-query";

import styled from "styled-components";
import { getCompanyBranches } from "../../service";
import { useNavigate } from "react-router-dom";

export const CustomSelect = styled(Select)`
  width: 100%;
`;

export const BranchSelect: React.FC<any> = ({ setBranch, branch }) => {
  const [options, setOptions] = useState<any>([]);
  const navigate = useNavigate()
  useQuery<any, Error>(["/company-branches"], () => getCompanyBranches(), {
    onSuccess(data) {
      data[0].id && setBranch(data[0].id);
      // navigate(`/delivery/${data[0].id}`)
      setOptions(
        data.map((e: any) => {
          return {
            value: e.id,
            label: e.name,
          };
        })
      );
    },
  });

  return (
    <div
      style={{
        padding: "5px",
      }}
    >
      <CustomSelect
        value={branch}
        onChange={setBranch}
        placeholder="Oddział"
        options={options}
      />
    </div>
  );
};
