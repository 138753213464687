import { createContext } from "react";

interface MenuContextProps {
  tableType: any;
  setTableType: any;
  productCategoryId: any;
  setProductCategoryId: any;
  extensionCategoryId: any;
  setExtensionCategoryId: any;
}

export const MenuContext = createContext<MenuContextProps | undefined>(
  undefined
);
