import { ReactNode, useState } from "react";
import { MenuContext } from "./MenuContext";
import React from "react";
export const MenuContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [tableType, setTableType] = useState<"PRODUCTS" | "EXTENSIONS">();
  const [productCategoryId, setProductCategoryId] = useState();
  const [extensionCategoryId, setExtensionCategoryId] = useState();

  return (
    <MenuContext.Provider
      value={{
        tableType,
        setTableType,
        productCategoryId,
        setProductCategoryId,
        extensionCategoryId,
        setExtensionCategoryId
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
