import React, { useState } from "react";
import { Flex, Layout, Spin } from "antd";
import { SlideMenu, MainContent } from "./view";
import { useQuery } from "react-query";
import { isLoggedIn as isUserLogin } from "./service/client";
import { Footer, Header } from "./components/organisms";
import { LoginForm } from "./components/organisms/login-form/LoginForm";

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { data, refetch, isFetching } = useQuery<any, Error>(
    ["/is-logged-in"],
    () => isUserLogin()
  );

  const isLoggedIn = data;

  if (isFetching) {
    return (
      <Flex
        style={{
          width: "100%",
          height: "100vh",
        }}
        justify="center"
        align="center"
      >
        <Spin size="large" />
      </Flex>
    );
  }

  if (isLoggedIn && data) {
    return (
      <Layout hasSider>
        <SlideMenu
          refetch={refetch}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        <Layout
          style={{
            minHeight: "100vh",
            marginLeft: collapsed ? 80 : 200,
            minWidth: "1000px",
          }}
        >
          <Header />
          <MainContent />
          <Footer />
        </Layout>
      </Layout>
    );
  }

  if (!isLoggedIn) {
    return <LoginForm refetch={refetch} />;
  }

  return null;
};

export default App;
