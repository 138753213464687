import { Button, Flex, Form, Select, Tag } from "antd";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteDeliveryZone,
  getBranchInfo,
  getCompanyBranches,
  getDeliveryZones,
} from "../../../service";
import { DeleteOutlined } from "@ant-design/icons";

export const DeliveryList: React.FC<any> = ({ height, zones, setZones }) => {
  const navigation = useNavigate();
  const { id } = useParams();
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState<any>(null);

  const query = useQueryClient();

  const { mutateAsync, isLoading: isDeleteLoading } = useMutation(
    deleteDeliveryZone,
    {
      onSuccess: async (data) => {
        await query.invalidateQueries(["/delivery-zones"]);
        await query.refetchQueries(["/delivery-zones"]);
      },
    }
  );

  const { isLoading } = useQuery<any, Error>(
    ["/branches-category"],
    () => getCompanyBranches(),
    {
      onSuccess(data) {
        const sellectOptions = data.map((e: any) => {
          return {
            value: e.id,
            label: e.name,
          };
        });
        const branchId = data.find(
          (e: any) => e.id?.toString() === id?.toString()
        );

        if (branchId) {
          setSelectedOptions(branchId?.id ?? null);
          navigation(`/delivery/${branchId?.id}`);
        } else {
          setSelectedOptions(data?.[0]?.id ?? null);
          navigation(`/delivery/${data?.[0]?.id}`);
        }

        setOptions(sellectOptions);
      },
    }
  );

  useQuery<any, Error>(
    ["/delivery-zones", id],
    () => getDeliveryZones({ id: selectedOptions }),
    {
      onSuccess(data) {
        setZones(data);
      },
      enabled: !!selectedOptions,
    }
  );

  return (
    <div
      style={{
        height: `calc(100vh - ${height}px)`,
        minHeight: `calc(100vh - ${height}px)`,
        maxHeight: `calc(100vh - ${height}px)`,
        overflow: "auto",
        background: "white",
      }}
    >
      <Form layout={"vertical"} style={{ padding: 5 }}>
        <Form.Item label="Oddział">
          <Select
            loading={isLoading}
            style={{ minWidth: 180 }}
            onChange={(id) => {
              setSelectedOptions(id);
              navigation(`/delivery/${id}`);
            }}
            value={selectedOptions}
            options={options}
          />
        </Form.Item>
      </Form>
      {zones
        .map((e: any) => {
          return {
            id: e.id,
            content: [
              {
                style: { fontWeight: 600, marginBottom: "20px" },
                texts: [
                  {
                    style: { fontWeight: 600, color: "#000" },
                    text: e.name,
                  },
                ],
              },
              {
                style: { marginBottom: "10px" },
                texts: [
                  {
                    style: {},
                    text: `Cena: ${(e.price/100).toFixed(2)} zł`,
                  },
                ],
              },
              {
                style: { marginBottom: "10px" },
                texts: [
                  {
                    style: {},
                    text: `Darmowa dostawa od: ${(e.freeAbove/100).toFixed(2)} zł`,
                  },
                ],
              },
            ],
            labels: [],
          };
        })
        .map((e: any) => {
          return (
            <>
              <Flex
                justify="end"
                style={{
                  background: "#fafafa",
                  width: "100%",
                  padding: "6px",
                }}
              >
                <Button
                  onClick={() => mutateAsync({ id: e.id })}
                  disabled={isDeleteLoading}
                  loading={isDeleteLoading}
                  danger
                  icon={<DeleteOutlined />}
                  shape="circle"
                  size="small"
                />
              </Flex>
              <div
                // onClick={() => navigation(`/delivery/${e.id}`)}
                style={{
                  width: "100%",
                  background: "white",
                  borderBottom: "2px solid #f0f0f0",
                  marginBottom: "16px",
                  padding: "5px",
                  cursor: "pointer",
                }}
              >
                {e.content.map((row: any) => {
                  return (
                    <div
                      style={{
                        display: row.texts.length > 1 ? "flex" : "block",
                        justifyContent:
                          row.texts.length > 1 ? "space-between" : "start",
                        ...row.style,
                      }}
                    >
                      {row.texts.map((text: any) => {
                        return (
                          <span style={{ ...text.style }}>{text.text}</span>
                        );
                      })}
                    </div>
                  );
                })}
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  {e.labels.map((element: any) => (
                    <Tag
                      style={{
                        marginBottom: "5px",
                      }}
                      color={element.color}
                    >
                      {element.text}
                    </Tag>
                  ))}
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};
